import React from 'react'

const Data = () => {
    return (
        <div className="home__data">

            <h3 className="home__subtitle">Fullstack Developer</h3>
            <div className="home__description">Hello, I'm <span className="my-name">Raphael Bahr</span> a Fullstack Developer Jr with a deep interest in technology and programming based in Brazil.</div>
            <a href="#contact" className="button button--flex">
                Write me!
            </a>
        </div>
    )
}

export default Data;