import Image1 from "../../assets/testimonial1.png";
import Image2 from "../../assets/testimonial2.png";
import Image3 from "../../assets/testimonial3.png";

export const Data = [
    {
        id: 1,
        image: Image1,
        title: "Blog",
        description:
            "My first front- end page, fully responsive.",
        link: "https://serene-rugelach-b14c9d.netlify.app",
        
    },
    {
        id: 2,
        image: Image2,
        title: "Ecommerce page",
        description:
            "First attempt Eletronic ecommerce page, using only css, html and js",
        link: "https://unique-marshmallow-7392f3.netlify.app/",
    },
    {
        id: 3,
        image: Image3,
        title: "First Portfolio",
        description:
            "My First portfolio, using just css, html and js",
        link: "https://endearing-arithmetic-cc8603.netlify.app/",
    },
]